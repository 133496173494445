<template>
  <div>
    <Loader v-if="pageLoading" :loading="pageLoading" />
    <StepNineReusable
        :job_title="jobInfo.title"
        :job_type="jobInfo.type"
        :location_type="jobInfo.is_remote === false ? 'On-Site' : 'Remote'"
        v-else
    >
      <template #mainContent>
        <div >
          <div class="mb-10" v-if="!editDescription">
            <div class="tw-flex tw-justify-between">
              <span class="profile mt-3">Job Description</span>
              <base-button color="#1E323F" text icon button-text="Edit" @click="editDescription = true;  nextButton = true">
                <template #left-icon>
                  <Edit2Icon size="1.4x" style="color: #1E323F"/>
                </template>
              </base-button>
            </div>
            <v-divider class="mb-10"></v-divider>
            <div>
              <span class="details tw-text-sm md:tw-text-lg" v-html="description"></span>
            </div>
          </div>
          <div v-if="editDescription" class="mt-3">
            <div class="tw-flex tw-justify-between">
              <span class="profile my-3">Job Description</span>
            </div>
            <vue-editor
                :editor-toolbar="customToolbar"
                v-model="editorText"

            ></vue-editor>
          </div>
          <div class="my-10" v-if="!editResponsibilities">
            <div class="tw-flex tw-justify-between">
              <span class="profile mt-3">Job Responsibilities</span>
              <base-button color="#1E323F" text icon button-text="Edit" @click="editResponsibilities = true;  nextButton = true">
                <template #left-icon>
                  <Edit2Icon size="1.4x" style="color: #1E323F"/>
                </template>
              </base-button>
            </div>
            <v-divider class="mb-10"></v-divider>
            <div>
              <span class="details tw-text-sm md:tw-text-lg" v-html="responsibilities"></span>
            </div>

          </div>
        </div>
        <div v-if="editResponsibilities" class="mt-5">
          <div class="tw-flex tw-justify-between">
            <span class="profile my-3">Job Responsibilities</span>
          </div>
          <vue-editor
              :editor-toolbar="customToolbar"
              v-model="responsibilities"
          ></vue-editor>
        </div>

      </template>
      <template #sideButton>
        <div class="lg:tw-flex  tw-justify-center tw-items-center" v-if="editDescription || editResponsibilities">
          <base-button @click="resetEditor" text button-text="Reset" outlined height="40px"></base-button>
          <base-button :loading="loading" @click="updateJobDescription" height="40px" class="ml-3 tw-hidden lg:tw-flex md:tw-flex" button-text="Save"></base-button>
        </div>
      </template>
      <template #footer>
        <BottomNavbar @editor-hide="editDescription = false" @click:back="backRoute"
                      :button-name="$route.query.edit?'Save ':'Next '" @click:next="updateJobDescription" :editor="editDescription"
                      :previous="!$route.query.edit" :loading="buttonLoading"></BottomNavbar>
      </template>
    </StepNineReusable>
  </div>
</template>

<script>
import StepNineReusable from "@/components/jobPosting/reuseables/StepNineReusable";
import BaseButton from "@/components/UIComponents/BaseButton";
import {Edit2Icon} from "vue-feather-icons";
import { VueEditor } from "vue2-editor";
import BottomNavbar from "@/components/jobPosting/reuseables/BottomNavbar";
import Loader from "../../UIComponents/reusablesIcon/Loader";
import {mapGetters} from "vuex";
import {getJobById, updateJobById} from "../../../services/api/APIService"

export default {
  name: "StepNine",
  components: {BottomNavbar, StepNineReusable, BaseButton, Edit2Icon, VueEditor, Loader},
  data() {
    return {
      editDescription: !!this.$route.query.edit,
      editResponsibilities : false,
      nextButton: false,
      loading: false,
      editorText: '',
      description: '',
      responsibilities : '',
      pageLoading : true,
      buttonLoading : false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          {align: ""},
          {align: "center"},
          {align: "right"},
          {align: "justify"}
        ],
        [
          {list: "ordered"},
          {list: "bullet"},
          "link"
        ],
        [{size: ["small", false, "large", "huge"]}]
      ],
    }
  },
  computed: {
    ...mapGetters('jobPosting', ['jobInfo', 'jobDescription']),
    sessionStorageId() {
      let job = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"));
      return job.id;
    },
    showButtons(){
      return  this.editResponsibilities || this.editDescription
    }
  },
  methods: {
    backRoute(){
      // this.createJobDescription()
      // if(this.editDescription === true) {
      //   this.editDescription = false
      // } else
      if (this.$route.query.jobId){
        this.$router.push({name: 'Job-stepEight', query:{jobId:this.$route.query.jobId}})
      }
      else {
        this.$router.push({name: 'Job-stepEight'})
      }
    },
   async nextRoute(){
      this.buttonLoading = true
      if (this.$route.query.edit){
       await this.updateJobDescription().then(() =>{
          this.buttonLoading = false
          this.$router.push({name:'JobSummary', query:{jobId:this.$route.query.jobId}})
        })
      }
      else {
        if (this.editDescription === false && this.nextButton === false) {
         await this.updateJobDescription().then(() => {
            this.buttonLoading = false
            this.$router.push({
              name: "Shortlisting",
              params: {
                jobId: this.sessionStorageId
              }
            })
          })

        } else if (this.nextButton === true) {
          this.buttonLoading = false
         await this.$router.push({
            name: "Shortlisting",
            params: {
              jobId: this.sessionStorageId
            }
          })
        } else {
          this.buttonLoading = false
         await this.$router.push({
            name: "Shortlisting",
            params: {
              jobId: this.sessionStorageId
            }
          })
        }
      }
    },

    async createJobInfo(){
      let data = {}
      data.id = this.sessionStorageId
      data.site_name = this.$siteName()

      await this.$store.dispatch('jobPosting/getAllJobInfo', data).then(()=>{})
          .catch(err =>{
            this.editResponsibilities = true
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })

    },
    async createJobDescription(){
      const item = this.jobInfo.title
      const array = item.split(' ')
      const payload ={}
      payload.level= this.jobInfo.experience_level
      console.log(array)
      if (array.length > 1){
        payload.job_title= array[1] +' '+ array[2]
      }
      else {
        payload.job_title = array[0]
      }
      if(this.sessionStorageId) {
        let data = {}
        data.id = this.sessionStorageId
        data.site_name = this.$siteName()
        await getJobById(data).then(
            async res =>{
              if(res.data.description === '') {
                await this.$store.dispatch('jobPosting/getAllJobDescription', payload).then(()=>{
                  this.pageLoading = false
                }).catch(err =>{
                  this.editResponsibilities = true
                  this.editDescription = true
                      this.pageLoading = false
                      console.log(err.response.data.detail)
                      this.$displaySnackbar({message:err.response.data.detail, success:false})
                    })
                this.description = this.jobDescription.description
                this.editorText = this.jobDescription.description
                this.responsibilities = this.jobDescription.responsibilities

                if (!this.jobDescription.description ){
                  this.editDescription = true
                }
                else if(!this.jobDescription.responsibilities){
                  this.editResponsibilities = true
                }

              } else {
                this.description = res.data.description
                this.editorText = res.data.description
                this.responsibilities = res.data.responsibilities

                if (!res.data.description ){
                  this.editDescription = true
                }
                else if(!res.data.description){
                  this.editResponsibilities = true
                }
              }
            })
      }
      else {
        await this.$store.dispatch('jobPosting/getAllJobDescription', payload)
        this.description = this.jobDescription.description
        this.editorText = this.jobDescription.description
        this.responsibilities = this.jobDescription.responsibilities

        if (!this.jobDescription.description ){
          this.editDescription = true
        }
        else if(!this.jobDescription.responsibilities){
          this.editResponsibilities = true
        }
      }
    },
    resetEditor() {
      this.editorText = ''
      this.responsibilities = ''
    },
    async updateJobDescription() {
      this.loading = true
      let currentJob = {}
      currentJob.description = this.editorText
      currentJob.responsibilities = this.responsibilities
      currentJob.site_name = this.$siteName()
      if(this.sessionStorageId && currentJob.description) {
        console.log(currentJob)
        await updateJobById(this.sessionStorageId, currentJob).then(async () =>{
          await this.createJobDescription();
          this.loading = false
          if (this.$route.query.edit){
            await this.$router.push({name: 'JobSummary'})
          }
          else {
            await this.$router.push({
              name: "Shortlisting",
              params: {
                jobId: this.sessionStorageId
              }
            })
          }
          this.loading = false
          this.editDescription = false
          this.editResponsibilities = false

        })
            .catch(err =>{
              this.loading = false
              console.log(err.response.data.detail)
              this.$displaySnackbar({message:err.response.data.detail, success:false})
            })
      }else {
        this.loading = false
        this.$displaySnackbar({message: 'Please fill in Job description', success: false})
      }

    },
  },
  async created() {
    this.pageLoading = true
    await this.createJobInfo();
    await this.createJobDescription();

    this.pageLoading = false

  }

}
</script>

<style scoped lang="scss">
.profile {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold ;
  font-size: 18px;
  line-height: 23px;
  color: #001343;
}
.details {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  color: #475661;
}

</style>