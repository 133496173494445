<template>
  <div>
    <job-posting-layout>
      <template #topContent>
        <div class="stepContent">
          <div>
            <p class="step mb-10 mt-5">Step 9 of 9</p>
          </div>
          <div class="tw-mb-3">
            <p class="job-title tw-text-2xl md:tw-text-5xl">{{ job_title }}</p>
          </div>
          <div class="mb-10 tw-flex tw-justify-between tw-w-full md:tw-w-8/12">
            <div class="tw-flex tw-justify-items-start">
              <div >
                <v-chip style="border: 1px solid #0C8B99 !important; padding: 10px" class="chip" color="#EBFAFC">
                  <BriefcaseIcon style="color: #475661" class="mr-1" size="1.5x"/>
                  <span class="loc">{{job_type}}</span>
                </v-chip>
                <v-chip style="border: 1px solid #FFDA5E !important;" class="ml-3 chip" :color="'#FFFCF0'">
                  <MapPinIcon style="color: #475661" class="mr-1" size="1.5x"/>
                  <span class="loc">{{location_type}}</span>
                </v-chip>
              </div>
            </div>
            <div class="tw-hidden lg:tw-block md:tw-block">
              <slot name="sideButton">
              </slot>
            </div>
          </div>

          <div class="tw-flex tw-w-full tw-justify-between tw-flex-col-reverse lg:tw-flex-row">
            <v-col md="8" >
              <slot name="mainContent">
                <p>main-content</p>
              </slot>
            </v-col>
            <v-col md="3" class="mt-3 tips-body tw-pb-6 tw-flex tw-justify-around tw-p-5 mb-5 tw-items-start">
                <InfoIcon class="mr-2 tw-mt-1" style="color: #45B4FF; height: max-content" size="3.6x"/>
                <div>
                  <label v-if="selectedRole" class="show-more">Please provide job description details</label>
                  <label v-else class="show-more">Create a detailed job description so you can attract the right candidates.</label>
                </div>
              </v-col>
          </div>

        </div>
      </template>
      <template #bottomNav>
        <slot name="footer"></slot>
      </template>
    </job-posting-layout>
  </div>
</template>

<script>
import JobPostingLayout from "@/components/jobPosting/layouts/JobPostingLayout";
import { BriefcaseIcon, MapPinIcon, InfoIcon} from "vue-feather-icons"

export default {
  name: "StepNineReusable",
  components: {JobPostingLayout, BriefcaseIcon, MapPinIcon, InfoIcon},
  props: {
    job_title: [String],
    job_type: [String],
    location_type: [String]
  },
  computed:{
    selectedRole(){
      return sessionStorage.getItem('selectedRole')
    }
  }
}
</script>

<style scoped lang="scss">
.step {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Enum-Grey/2 */

  color: #475661;
}
.job-title {
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;

  color: #001343;
}
.tips-body{
  background: #F7FCFF;
  border-radius: 10px;
  height: max-content;
  border: 1px solid #45B4FF;
  @media screen and(min-width: 1224px) {
    width: max-content;
  }
}

.show-more{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}
.loc {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #475661;
}


</style>